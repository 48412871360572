import { Direction } from 'react-range'
import { Form } from 'react-bootstrap'

import { ContentEditor, InputRange, Input } from 'components/UIComponents'
import { isTrue } from 'helpers'

export const MultipleChoiceNumericalSubquestion = ({
  useSlider,
  orientation,
  value,
  isFocused,
}) => {
  return (
    <Form.Group className="d-flex w-100 gap-3 align-items-center ">
      <Form.Label style={{ minWidth: 'fit-content' }}>
        <ContentEditor
          disabled={true}
          value={value}
          className="multi-choice-content-editor choice"
        />
      </Form.Label>
      {isTrue(useSlider) ? (
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <InputRange
            showInput={false}
            direction={orientation ? orientation : Direction.Right}
          />
        </div>
      ) : (
        <div
          className="w-100"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <Input
            placeholder="Enter your answer here."
            dataTestId={'multiple-choice-comment-input'}
            disabled={isFocused}
            type={'number'}
          />
        </div>
      )}
    </Form.Group>
  )
}
