import { Input, ToggleButtons } from 'components/UIComponents'

import { QuestionTypeAttribute } from './QuestionTypeAttribute'
import { QuestionCodeAttribute } from './QuestionCodeAttribute'
import { RatingItems } from 'components/UIComponents/RatingItems'

export const GENERAL_ATTRIBUTES = {
  QUESTION_CODE: {
    component: QuestionCodeAttribute,
    attributePath: 'title',
    props: {},
    returnValues: ['title'],
  },
  QUESTION_TYPE: {
    component: QuestionTypeAttribute,
    attributePath: 'questionThemeName',
    props: {},
    returnValues: ['type', 'questionThemeName'],
  },
  MANDATORY: {
    component: ToggleButtons,
    attributePath: 'mandatory',
    props: {
      labelText: 'Mandatory',
      id: 'mandatory',
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Soft', value: 'S' },
        { name: 'Off', value: false },
      ],
      defaultValue: false,
    },
    returnValues: ['mandatory'],
  },
  NUMBERS_ONLY: {
    component: ToggleButtons,
    attributePath: 'attributes.numbers_only',
    props: {
      labelText: 'Numbers only',
      id: 'numbers-only-attribute-question-settings',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  MAX_CHARACTERS: {
    component: Input,
    attributePath: 'attributes.maximum_chars',
    props: {
      labelText: 'Max characters',
      id: 'maximum-characters',
      allowEmpty: true,
      placeholder: 500,
      type: 'number',
    },
  },
  ENCRYPTED: {
    component: ToggleButtons,
    attributePath: 'encrypted',
    props: {
      labelText: 'Store answers encrypted',
      id: 'general-encrypted',
      toggleOptions: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      defaultValue: false,
    },
    returnValues: ['encrypted'],
  },
  SAVE_AS_DEFAULT: {
    component: ToggleButtons,
    attributePath: 'attributes.save_as_default',
    props: {
      labelText: 'Save As Default Values',
      id: 'save-as-default-values',
      toggleOptions: [
        { name: 'Yes', value: 'Y' },
        { name: 'No', value: 'N' },
      ],
      defaultValue: 'N',
    },
  },
  CLEAR_DEFAULT_VALUES: {
    component: ToggleButtons,
    attributePath: 'attributes.clear_default',
    props: {
      labelText: 'Clear Default Values',
      id: 'clear-default-values',
      toggleOptions: [
        { name: 'On', value: 'Y' },
        { name: 'Off', value: 'N' },
      ],
    },
  },
  OTHER: {
    component: ToggleButtons,
    attributePath: 'other',
    props: {
      labelText: 'Other',
      id: 'general-other',
      toggleOptions: [
        { name: 'On', value: true },
        { name: 'Off', value: false },
      ],
      defaultValue: false,
    },
    returnValues: ['other'],
  },
  INPUT_VALIDATION: {
    component: Input,
    attributePath: 'preg',
    props: {
      labelText: 'Input validation',
    },
    returnValues: ['preg'],
  },
  LOGIC: {
    component: Input,
    attributePath: 'relevance',
    props: {
      labelText: 'Logic',
    },
    returnValues: ['relevance'],
    hidden: !process.env.REACT_APP_DEV_MODE,
  },
  RATING_ITEMS: {
    component: RatingItems,
    attributePath: '',
    props: {
      labelText: 'Rating Items',
    },
  },
}
