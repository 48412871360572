import { DateTimePickerComponent, ToggleButtons } from 'components/UIComponents'

import getSiteUrl from '../../../helpers/getSiteUrl'

export const PublicationAccessSettingsBlocks = {
  DATE: {
    title: 'Publication date',
    settings: {
      START_DATE: {
        keyPath: 'startDate',
        props: {
          id: 'start-date-time',
          mainText: 'Start date time',
          childComponent: DateTimePickerComponent,
          childOnNewLine: true,
          noPermissionDisabled: true,
        },
      },
      EXPIRES: {
        keyPath: 'expires',
        props: {
          id: ' end-date-time',
          mainText: 'End date time',
          childComponent: DateTimePickerComponent,
          noPermissionDisabled: true,
          childOnNewLine: true,
        },
      },
    },
  },
  ACCESS: {
    settings: {
      LIST_PUBLIC: {
        keyPath: 'listPublic',
        props: {
          id: 'listPublic',
          mainText: 'Link survey on ',
          link: getSiteUrl(),
          linkText: 'public index page',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      USE_COOKIE: {
        keyPath: 'useCookie',
        props: {
          id: 'useCookie',
          mainText: 'Set cookie to prevent repeated participation',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      USE_CAPTCHA_ACCESS: {
        keyPath: 'useCaptchaAccess',
        props: {
          id: 'useCaptchaAccess',
          mainText: 'Use CAPTCHA for survey access',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      USE_CAPTCHA_REGISTRATION: {
        keyPath: 'useCaptchaRegistration',
        props: {
          id: 'useCaptchaRegistration',
          mainText: 'Use CAPTCHA for registration',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      USE_CAPTCHA_SAVE_LOAD: {
        keyPath: 'useCaptchaSaveLoad',
        props: {
          id: 'useCaptchaSaveLoad',
          mainText: 'Use CAPTCHA for save and load',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
    },
  },
}
