import { cloneDeep, merge } from 'lodash'

import { getQuestionById } from 'helpers/getQuestionById'
import { getGroupById } from 'helpers/getGroupById'

import { EntitiesPath, Entities, Operations, EntitiesType } from './'
import {
  getEntityInfo,
  arrayDeleteItem,
  getSubquestionById,
  getAnswerById,
  filterCollection,
  hasTempId,
} from 'helpers'

export class OperationsBuffer {
  /**
   * Information on how to handle the operations can be found in the backend in the following directory:
   * ls-ce\public\application\libraries\Api\Command\V1\SurveyPatch
   * A operation should be constructed according to the info in the directory mentioned above.
   */
  operations = []

  // Buffer hash is used to check if the buffer has changed since the last save
  bufferHash = ''

  constructor(operations = [], hash = '') {
    this.operations = cloneDeep(operations)
    this.bufferHash = hash
  }

  getOperations = () => {
    return cloneDeep(this.operations)
  }

  setBufferHash = (hash) => {
    this.bufferHash = hash
  }

  // todo: if the user is trying to delete a question group with a tempId
  // - then loop over the questions with that id and remove the operations
  addOperation = (operation, updateCurrentOperation = true) => {
    const { id, op, entity, props, error = false } = operation

    const operationIndex = this.findIndex(id, op, entity)

    const isNewEntity = hasTempId(operation.id?.toString())
    const entityisAnswerOrSubquestion =
      entity === Entities.answer || entity === Entities.subquestion

    // If the user is updating an entity which is waiting to be created,
    // - we update the "create" operation and discard the "update" operation
    if (
      isNewEntity &&
      operation.op !== Operations.create &&
      entityisAnswerOrSubquestion
    ) {
      const newOperationIndex = this.findIndex(id, Operations.create, entity)
      const newOperation = this.operations[newOperationIndex]

      if (newOperation && op === Operations.update) {
        merge(newOperation.props, props)
        return
      }
    }

    if (op === Operations.delete) {
      // remove all the operations that related to that entity.
      // since the entity will be deleted anyways.
      this.operations = this.operations.filter(
        (operation) => operation.id != id
      )

      if (entity === Entities.questionGroup) {
        this.operations = this.operations.filter(
          (operation) => operation.props?.question?.gid !== id
        )
      }

      // if the user is trying to delete an answer or a subquestion, then we also remove all the operations that depend on that entity.
      if (entity === Entities.answer) {
        this.operations.forEach((operation) => {
          if (operation.entity === Entities.answer && operation.props) {
            operation.props = filterCollection(
              operation?.props,
              (answer) => answer?.aid !== id
            )
          }
        })
      } else if (entity === Entities.subquestion) {
        this.operations.forEach((operation) => {
          if (operation.entity === Entities.subquestion && operation.props) {
            operation.props = filterCollection(
              operation?.props,
              (subquestion) => subquestion?.qid !== id
            )
          }
        })
      }

      // remove the operations with no props if they are not a delete operation.
      this.operations = this.operations.filter(
        (operation) =>
          operation.op === Operations.delete || operation.props.length
      )

      // if we are trying to delete a newly created entity (has a tempId),
      // then we basically just return and we don't add any operations.
      if (isNewEntity) {
        return
      }
    }

    if (operationIndex === -1 || !updateCurrentOperation) {
      this.operations.push({ id, op, entity, error, props })
    } else if (updateCurrentOperation) {
      if (entityisAnswerOrSubquestion) {
        this.operations[operationIndex].props = props
      } else {
        merge(this.operations[operationIndex].props, props)
      }
      delete this.operations[operationIndex].error
    }
  }

  setOperations = (operations) => {
    this.operations = operations
  }

  clearBuffer = () => {
    this.setOperations([])
  }

  removeOperation = (id, op, entity) => {
    const operationIndex = this.findIndex(id, op, entity)
    this.operations = arrayDeleteItem(this.operations, operationIndex)[0]

    return operationIndex !== -1
  }

  findIndex = (id, op, entity) => {
    return this.operations.findIndex(
      (item) =>
        (item.id == id || (item.id?.sid == id?.sid && id?.sid)) &&
        (op === undefined || item.op === op) && // Check item.op === op only if op is defined
        (entity === undefined || item.entity === entity) // Check item.entity === entity only if entity is defined
    )
  }

  isEmpty = () => {
    return this.operations.length === 0
  }

  applyOperations(survey) {
    const operations = [...this.operations]
    let modifiedSurvey = { ...survey }

    if (!operations.length) {
      return modifiedSurvey
    }

    operations.forEach((operation) => {
      switch (operation.op) {
        case Operations.delete:
          this.handleDeleteOperation(operation, modifiedSurvey)
          break
        default:
          this.handleUpdateOperation(operation, modifiedSurvey)
          break
      }
    })

    return modifiedSurvey
  }

  handleDeleteOperation(operation, survey) {
    if (operation.error) return

    if (operation.entity === Entities.subquestion) {
      const { questionIndex, groupIndex, subquestionIndex } =
        getSubquestionById(operation.id, survey)
      survey.questionGroups[groupIndex]?.questions[
        questionIndex
      ]?.subquestions?.splice(subquestionIndex, 1)
    } else if (operation.entity === Entities.answer) {
      const { questionIndex, groupIndex, answerIndex } = getAnswerById(
        operation.id,
        survey
      )
      survey.questionGroups[groupIndex]?.questions[
        questionIndex
      ]?.answers?.splice(answerIndex, 1)
    } else if (operation.entity === Entities.question) {
      const { questionIndex, groupIndex } = getQuestionById(
        operation.id,
        survey
      )
      survey.questionGroups[groupIndex]?.questions?.splice(questionIndex, 1)
    } else {
      const { index } = getGroupById(operation.id, survey)
      survey.questionGroups?.splice(index, 1)
    }
  }

  handleUpdateOperation(operation, survey) {
    if (operation.entity === Entities.questionGroupReorder) {
      for (const [groupId, groupProps] of Object.entries(operation.props)) {
        const entityInfo =
          getEntityInfo(parseInt(groupId), survey, EntitiesType.group)[
            EntitiesType.group
          ] || {}

        entityInfo.sortOrder = groupProps?.sortOrder
        const entries = Object.entries(groupProps)

        for (const questionId in entries[1][1]) {
          getEntityInfo(parseInt(questionId), survey, EntitiesType.question)[
            EntitiesType.question
          ].sortOrder = entries[1][1][questionId].sortOrder
        }
      }
    } else {
      for (const [key, value] of Object.entries(operation.props)) {
        let path = this.resolvePath(operation.entity, key)
        if (!path) continue

        let target = this.resolveTarget(path, operation.id, survey)
        if (!target) continue

        this.applyValue(target, key, value)
      }
    }
  }

  applyValue(target = {}, key = '', value = {}) {
    if (value === null || typeof value !== 'object') {
      target[key] = value
    } else {
      for (const [subKey, subValue] of Object.entries(value)) {
        if (target.hasOwnProperty(subKey)) {
          target[subKey] = subValue
        }
      }
    }
  }

  resolvePath(entity, key) {
    const pathTemplate = EntitiesPath[entity]

    if (!pathTemplate) {
      return null
    }

    return pathTemplate
      .replace('$language', key)
      .replace('$attributeName', key)
      .split('.')
  }

  resolveTarget(path, id, survey) {
    let entityType = path.shift()
    let entity

    switch (entityType) {
      case EntitiesType.question:
        entity = getEntityInfo(id, survey, EntitiesType.question)[
          EntitiesType.question
        ]
        break
      case EntitiesType.groupName:
        entity = getEntityInfo(id, survey, EntitiesType.group)[
          EntitiesType.group
        ]
        break
      default:
        entity = survey // Assuming survey is the target for global updates.
    }

    if (!entity) {
      return undefined
    }

    return path.reduce(
      (acc, key) => (acc && typeof acc[key] === 'object' && acc[key]) || acc,
      entity
    )
  }

  isCreateOp(data) {
    return data?.entity && data?.op === Operations.create
  }

  isOperationReadyForPatch(operation, hasCreateOp, hasDeletOp) {
    if (operation.op !== Operations.create && hasCreateOp) {
      return false
    } else if (operation.op !== Operations.delete && hasDeletOp) {
      return false
    }

    const isQuestionGroupHasATempIdAndOpIsNotGroupCreate =
      hasTempId(operation.props, 'gid') &&
      operation.op !== Operations.create &&
      operation.entity === Entities.questionGroup

    const isAddingSubquestionAndQuestionHasATempId =
      operation.entity === Entities.subquestion &&
      operation.props &&
      hasTempId(operation.props[0], 'parentQid')

    const isAddingAnswerAndQuestionHasATempid =
      operation.entity === Entities.answer &&
      operation.props &&
      hasTempId(operation.props[0], 'qid')

    if (
      isQuestionGroupHasATempIdAndOpIsNotGroupCreate ||
      // In case of subquestions check if the question has a tempId
      isAddingSubquestionAndQuestionHasATempId ||
      // In case of answers check if the question has a tempId
      isAddingAnswerAndQuestionHasATempid ||
      operation.error
    ) {
      return false
    }

    return true
  }
}
