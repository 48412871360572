import { useQuery } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { QuestionTypeInfo } from 'components'
import { EntitiesType } from 'helpers'
import { queryClient } from 'queryClient'

export const useFocused = (focused, groupIndex, questionIndex) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { surveyId } = useParams()
  const { data } = useQuery({
    queryKey: ['focusedQuestion'],
    queryFn: () => {
      return {
        focused,
        groupIndex,
        questionIndex,
      }
    },
  })

  const setFocused = (
    focusElement,
    groupIndex,
    questionIndex,
    unfocus = true
  ) => {
    if (unfocus) {
      unFocus()
    }

    const focused = cloneDeep(focusElement) || {}

    if (questionIndex !== undefined) {
      navigate(`/survey/${surveyId}?${EntitiesType.question}=${focused.qid}`)
    } else if (groupIndex !== undefined) {
      navigate(`/survey/${surveyId}?${EntitiesType.group}=${focused.gid}`)
    } else if (focused.info?.type === QuestionTypeInfo.WELCOME_SCREEN.type) {
      navigate(`/survey/${surveyId}?${EntitiesType.welcomeScreen}`)
    } else if (focused.info?.type === QuestionTypeInfo.END_SCREEN.type) {
      navigate(`/survey/${surveyId}?${EntitiesType.endScreen}`)
    }

    return queryClient.setQueryData(['focusedQuestion'], {
      focused: focused,
      groupIndex,
      questionIndex,
    })
  }

  const unFocus = () => {
    navigate(location.pathname)
    return queryClient.setQueryData(['focusedQuestion'], {})
  }

  return {
    setFocused,
    unFocus,
    ...data,
  }
}
