import { LANGUAGE_CODES, NEW_OBJECT_ID_PREFIX, SCALE_1 } from 'helpers'
import { RandomNumber } from './RandomNumber'

export const getQuestionExample = ({
  gid,
  qid,
  sid,
  parentQid = 0,
  questionThemeName = 'longfreetext',
  type = 'T',
  sortOrder = 1,
  scaleId = SCALE_1,
  language = LANGUAGE_CODES.EN,
  title,
  answers = [],
  subquestions = [],
  attributes = [],
  mandatory = false,
  encrypted = false,
  relevance = '1',
  newObject = false,
}) => {
  return {
    sid,
    qid: qid ? qid : `${NEW_OBJECT_ID_PREFIX}${RandomNumber()}`,
    gid,
    type,
    scaleId,
    sortOrder,
    questionThemeName,
    parentQid,
    tempId: qid,
    title: title ? title : `${parentQid ? 'SQ' : 'Q'}${RandomNumber()}`,
    preg: null,
    other: false,
    mandatory,
    encrypted,
    moduleName: null,
    sameDefault: null,
    relevance,
    sameScript: null,
    newObject,
    l10ns: {
      [language]: {
        qid,
        question: '',
        language: [language],
      },
    },
    attributes,
    answers,
    subquestions,
  }
}
