import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { SideBar } from 'components/SideBar'
import { SurveyStructure } from 'components/SurveyStructure'
import { TooltipContainer } from 'components/TooltipContainer/TooltipContainer'
import { useSurvey, useAppState } from 'hooks'
import { STATES, SURVEY_PANELS } from 'helpers'

import { SurveyPanel } from 'components/SurveySettings/SurveyPanel'
import {
  menuOptions,
  presentationOptions,
  settingsOptions,
} from 'components/SurveySettings/panelOptions'

import { FeedbackButton } from './FeedbackButton'

export const LeftSideBar = ({ surveyId }) => {
  const navigate = useNavigate()

  const {
    survey = {},
    update,
    surveyMenus = {},
    surveyHash,
  } = useSurvey(surveyId)
  const [currentOpenPanel = {}, setCurrentOpenPanel] = useAppState(
    STATES.CURRENT_OPEN_PANEL
  )

  const menuEntries = useMemo(() => {
    const menuKeys = Object.keys(surveyMenus)

    // groupedMenuEntries as an array of arrays => [[ 'generalsettings', 'translations', 'permissions' ], ['structure', 'elements', 'questions' ]]
    const groupedMenuEntries = menuKeys.map((key) => {
      return Object.keys(surveyMenus[key].entries).map((entryKey) => {
        return {
          entryKey,
          menuTitle: surveyMenus[key].entries[entryKey].menuTitle,
        }
      })
    })

    /**
     * change the groupedMenuEntries into an object for easier access
     * {
     *  generalsettings: { menuTitle: 'General Settings' },
     *  translations: { menuTitle: 'Translations' },
     *  ...
     * }
     */
    return [].concat(...groupedMenuEntries)
  }, [surveyMenus]).reduce((acc, item) => {
    acc[item.entryKey] = {
      menuTitle: item.menuTitle,
    }
    return acc
  }, {})

  const handlePanelChange = (panelInfo) => {
    if (panelInfo.panel === currentOpenPanel.panel) {
      return
    }

    if (panelInfo.getRedirectUrl && !process.env.STORYBOOK_DEV) {
      window.open(panelInfo.getRedirectUrl(survey.sid), '_self')
    } else {
      navigate(`/survey/${survey.sid}/${panelInfo.panel}`)
    }

    if (process.env.STORYBOOK_DEV) {
      if (panelInfo.panel) {
        if (panelInfo) {
          setCurrentOpenPanel({
            ...panelInfo,
            menu: currentOpenPanel.menu,
          })
        }
      } else if (currentOpenPanel.panel !== SURVEY_PANELS.structure.panel) {
        setCurrentOpenPanel({})
      }
    }
  }

  const Panel = useMemo(() => {
    let options = []

    if (currentOpenPanel.panel === SURVEY_PANELS.presentation.panel) {
      options = presentationOptions(surveyId, menuEntries)
    } else if (currentOpenPanel.panel === SURVEY_PANELS.settings.panel) {
      options = settingsOptions(surveyId, menuEntries)
    } else if (currentOpenPanel.panel === SURVEY_PANELS.menu.panel) {
      options = menuOptions(surveyId, menuEntries, survey.active)
    } else if (currentOpenPanel.panel === SURVEY_PANELS.structure.panel) {
      return (
        <SurveyStructure
          survey={survey}
          surveyId={surveyId}
          update={(questionGroups) => {
            survey.questionGroups = questionGroups
            update({ ...survey })
          }}
        />
      )
    }

    if (options.length) {
      options = options.filter((option) =>
        Object.keys(menuEntries).includes(option.menu)
      )
      return <SurveyPanel label={currentOpenPanel.label} options={options} />
    }

    return null
  }, [
    surveyId,
    surveyHash,
    currentOpenPanel.panel,
    currentOpenPanel.label,
    survey.active,
  ])

  return (
    <SideBar
      testId="left-sidebar"
      visible={true}
      className={'sidebar sidebar-left'}
    >
      <div className="d-flex h-100">
        <div className="sidebar-icons d-flex flex-wrap align-content-between pb-2">
          <div>
            {Object.keys(SURVEY_PANELS).map((panelKey, index) => {
              if (
                SURVEY_PANELS[panelKey] === SURVEY_PANELS.quickTranslation &&
                !survey.additionalLanguages?.trim().length
              ) {
                return null
              }

              const panelInfo = SURVEY_PANELS[panelKey]
              return (
                <div
                  key={panelInfo.panel}
                  onClick={() => {
                    handlePanelChange(panelInfo)
                  }}
                  className={classNames('cursor-pointer', {
                    'margin-top-10': index,
                  })}
                  data-testid={`btn-${panelInfo.panel}-open`}
                >
                  <TooltipContainer
                    offset={[0, 20]}
                    placement="right"
                    tip={panelInfo.label}
                  >
                    <panelInfo.icon
                      className={`${
                        currentOpenPanel.panel === panelInfo.panel
                          ? 'text-black'
                          : 'text-white'
                      } fill-current`}
                      bgcolor={`${
                        currentOpenPanel.panel === panelInfo.panel
                          ? '#EEEFF7'
                          : '#333641'
                      }`}
                    />
                  </TooltipContainer>
                </div>
              )
            })}
          </div>
          <FeedbackButton />
        </div>
        {Panel}
      </div>
    </SideBar>
  )
}
