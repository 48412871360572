import React from 'react'
import { Form } from 'react-bootstrap'
import classNames from 'classnames'

export const SurveySetting = (props) => {
  const childComponentOnNewLine = props?.childOnNewLine
  if (props.renderFullWidth) {
    return (
      <div className="mt-2">
        <Form.Label>{props.subText}</Form.Label>
        <div className={` ${props.extraClass || ''}`}>
          <props.childComponent {...props} />
        </div>
      </div>
    )
  }

  return !childComponentOnNewLine ? (
    <div className="mt-2 d-flex align-items-center">
      <div className="w-50">
        <p className="h6 mb-0">
          {props.mainText}
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            {props.linkText}
          </a>
        </p>
        {props.subText && (
          <Form.Label
            className={classNames('mb-0 text-secondary', {
              'd-none': !process.env.REACT_APP_DEV_MODE,
            })}
          >
            {props.subText}
          </Form.Label>
        )}
      </div>
      <div className={`w-50 ms-2 ${props.extraClass || ''}`}>
        <props.childComponent {...props} />
      </div>
    </div>
  ) : (
    <div className="mt-3">
      <div className="w-100 mb-1">
        <p className="h6 mb-0">{props.mainText}</p>
        {props.subText && (
          <Form.Label
            className={classNames('mb-0 text-secondary', {
              'd-none': !process.env.REACT_APP_DEV_MODE,
            })}
          >
            {props.subText}
          </Form.Label>
        )}
      </div>
      <div className={`w-100 ${props.extraClass || ''}`}>
        <props.childComponent {...props} />
      </div>
    </div>
  )
}
