import { Entities, getSettingValueFromSurvey, STATES } from 'helpers'

import { ContentEditor, Select, ToggleButtons } from 'components/UIComponents'

export const PrivacyPolicySettingsBlocks = {
  TOGGLES: {
    settings: {
      SHOW_SURVEY_POLICY_NOTICE: {
        keyPath: 'showSurveyPolicyNotice',
        props: {
          id: 'show-survey-policy-notice',
          mainText: 'Show privacy policy text with mandatory checkbox',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'No', value: 0 },
            { name: 'Inline', value: 1 },
            { name: 'Collapsible', value: 2 },
          ],
          noPermissionDisabled: true,
        },
      },
      SHOW_LEGAL_NOTICE_BUTTON: {
        keyPath: 'showLegalNoticeButton',
        renderExistCondition: 'showLegalNoticeButton',
        props: {
          id: 'show-legal-notice',
          mainText: 'Show link to legal notice in survey',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
        condition: {
          render: {
            get settings() {
              return [
                PrivacyPolicySettingsBlocks.TOGGLES.settings
                  .SHOW_LEGAL_NOTICE_BUTTON,
              ]
            },
            check: ([legalNoticeButton], globalStates) => {
              const { survey } = globalStates[STATES.SURVEY]
              // only show when prop is in survey obj (CE doesn't have this)
              return Object.hasOwn(survey, legalNoticeButton.setting.keyPath)
            },
          },
        },
      },
      SHOW_DATA_POLICY_BUTTON: {
        keyPath: 'showDataPolicyButton',
        renderExistCondition: 'showDataPolicyButton',
        props: {
          id: 'show-privacy-policy',
          mainText: 'Show link to data policy in survey',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
        condition: {
          render: {
            get settings() {
              return [
                PrivacyPolicySettingsBlocks.TOGGLES.settings
                  .SHOW_DATA_POLICY_BUTTON,
              ]
            },
            check: ([policyButton], globalStates) => {
              const { survey } = globalStates[STATES.SURVEY]
              // only show when prop is in survey obj (CE doesn't have this)
              return Object.hasOwn(survey, policyButton.setting.keyPath)
            },
          },
        },
      },
    },
  },
  TEXTS: {
    title: 'Languages and texts',
    settings: {
      LANGUAGE_HELPER: {
        keyPath: 'languageHelper',
        helperSetting: true,
        selectOptions: (globalStates) => {
          const { languages } = globalStates[STATES.SITE_SETTINGS]
          const { survey } = globalStates[STATES.SURVEY]

          const surveyLanguages = survey.language
            .concat(` ${survey.additionalLanguages}`)
            .split(' ')

          return surveyLanguages.map((option) => {
            let addOn = option === survey.language ? ' (base language)' : ''
            let languageOption = {
              value: option,
              label: languages
                ? languages[option]?.description + addOn
                : 'no data available',
            }

            return languageOption
          })
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelper =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]

          return languageHelper ? languageHelper : survey.language
        },
        props: {
          id: 'datasecurity-language',
          mainText: 'Language',
          childComponent: Select,
          noPermissionDisabled: true,
          childOnNewLine: true,
          dataTestId: 'datasecurity-language',
        },
      },
      LEGAL_NOTICE: {
        entity: Entities.languageSetting,
        keyPath: 'languageSettings.legalNotice',
        renderExistCondition: 'showLegalNoticeButton',
        props: {
          childComponent: ContentEditor,
          childOnNewLine: true,
          extraClass: 'editable-content-editor textarea',
          id: 'legal-notice-message',
          mainText: 'Legal Notice Message',
          placeholder: 'Legal Notice Message',
          noPermissionDisabled: true,
        },
        condition: {
          render: {
            get settings() {
              return [
                PrivacyPolicySettingsBlocks.TOGGLES.settings
                  .SHOW_LEGAL_NOTICE_BUTTON,
              ]
            },
            check: ([showLegalNoticeButton]) => {
              // only show when showLegalNoticeButton is enabled
              return showLegalNoticeButton.value
            },
          },
        },
        formatUpdateValue: (value, globalStates) => {
          const {
            survey: { languageSettings },
          } = globalStates[STATES.SURVEY]

          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]

          const updateValue = {
            ...languageSettings,
            [languageHelperValue]: {
              ...languageSettings[languageHelperValue],
              legalNotice: value,
            },
          }

          return {
            updateValue,
            updateValueKey: 'languageSettings',
            operationValue: { legalNotice: value },
            updateOperationKey: languageHelperValue,
          }
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]
          const settingValue = getSettingValueFromSurvey(
            survey,
            PrivacyPolicySettingsBlocks.TEXTS.settings.LEGAL_NOTICE,
            languageHelperValue
          )

          return settingValue
        },
      },
      POLICY_NOTICE: {
        entity: Entities.languageSetting,
        keyPath: 'languageSettings.policyNotice',
        props: {
          childComponent: ContentEditor,
          childOnNewLine: true,
          mainText: 'Privacy policy message',
          id: 'privacy-policy-message',
          placeholder: 'Privacy policy message',
          extraClass: 'editable-content-editor textarea',
          noPermissionDisabled: true,
        },
        formatUpdateValue: (value, globalStates) => {
          const {
            survey: { languageSettings },
          } = globalStates[STATES.SURVEY]

          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]

          const updateValue = {
            ...languageSettings,
            [languageHelperValue]: {
              ...languageSettings[languageHelperValue],
              policyNotice: value,
            },
          }

          return {
            updateValue,
            updateValueKey: 'languageSettings',
            operationValue: { policyNotice: value },
            updateOperationKey: languageHelperValue,
          }
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]
          const settingValue = getSettingValueFromSurvey(
            survey,
            PrivacyPolicySettingsBlocks.TEXTS.settings.POLICY_NOTICE,
            languageHelperValue
          )

          return settingValue
        },
      },
      POLICY_ERROR: {
        entity: Entities.languageSetting,
        keyPath: 'languageSettings.policyError',
        props: {
          childComponent: ContentEditor,
          childOnNewLine: true,
          mainText: 'Privacy policy error message',
          id: 'privacy-policy-message',
          placeholder: 'Privacy policy error message',
          extraClass: 'editable-content-editor textarea',
          noPermissionDisabled: true,
        },
        formatUpdateValue: (value, globalStates) => {
          const {
            survey: { languageSettings },
          } = globalStates[STATES.SURVEY]

          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]

          const updateValue = {
            ...languageSettings,
            [languageHelperValue]: {
              ...languageSettings[languageHelperValue],
              policyError: value,
            },
          }

          return {
            updateValue,
            updateValueKey: 'languageSettings',
            operationValue: { policyError: value },
            updateOperationKey: languageHelperValue,
          }
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]
          const settingValue = getSettingValueFromSurvey(
            survey,
            PrivacyPolicySettingsBlocks.TEXTS.settings.POLICY_ERROR,
            languageHelperValue
          )

          return settingValue
        },
      },
      POLICY_NOTICE_LABEL: {
        entity: Entities.languageSetting,
        keyPath: 'languageSettings.policyNoticeLabel',
        props: {
          childComponent: ContentEditor,
          childOnNewLine: true,
          mainText: 'Label text for the privacy policy checkbox',
          id: 'privacy-policy-label-message',
          placeholder: 'Label text for the privacy policy checkbox',
          extraClass: 'editable-content-editor',
          noPermissionDisabled: true,
        },
        formatUpdateValue: (value, globalStates) => {
          const {
            survey: { languageSettings },
          } = globalStates[STATES.SURVEY]

          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]

          const updateValue = {
            ...languageSettings,
            [languageHelperValue]: {
              ...languageSettings[languageHelperValue],
              policyNoticeLabel: value,
            },
          }

          return {
            updateValue,
            updateValueKey: 'languageSettings',
            operationValue: { policyNoticeLabel: value },
            updateOperationKey: languageHelperValue,
          }
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const helperSettings = globalStates[STATES.HELPER_SETTINGS]
          const languageHelperValue =
            helperSettings[
              PrivacyPolicySettingsBlocks.TEXTS.settings.LANGUAGE_HELPER.keyPath
            ]
          const settingValue = getSettingValueFromSurvey(
            survey,
            PrivacyPolicySettingsBlocks.TEXTS.settings.POLICY_NOTICE_LABEL,
            languageHelperValue
          )

          return settingValue
        },
      },
    },
  },
}
