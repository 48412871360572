import { Editor, Home } from 'pages'
import AuthGate from 'components/AuthGate'

import { Unauthorized, NotFound } from './pages/Errors'

const routes = [
  (process.env.REACT_APP_DEMO_MODE === 'true' ||
    process.env.REACT_APP_DEV_MODE === 'true') && {
    path: '/',
    element: <Home />,
  },
  process.env.REACT_APP_DEMO_MODE === 'true' && {
    path: '/survey',
    element: (
      <AuthGate>
        <Editor />
      </AuthGate>
    ),
  },
  {
    path: '/survey/:surveyId',
    element: (
      <AuthGate>
        <Editor />
      </AuthGate>
    ),
  },
  {
    path: '/survey/:surveyId/:panel?/:menu?',
    element: (
      <AuthGate>
        <Editor />
      </AuthGate>
    ),
  },
  {
    path: '/401',
    element: <Unauthorized />,
  },
  {
    path: '/404',
    element: <NotFound />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export default routes
