import { Input, Select } from 'components/UIComponents'
import { formatOptions, STATES, themeOptions } from 'helpers'

import { Theme } from '../GeneralSettings/Theme'

export const GeneralSettingsBlocks = {
  GENERAL: {
    title: 'General',
    settings: {
      LANGUAGES: {
        keyPath: 'additionalLanguages',
        formatUpdateValue: (languagesArray, globalStates) => {
          const { language } = globalStates[STATES.SURVEY].survey

          const languagesArrayWithoutBaseLanguage = languagesArray.filter(
            (lang) => lang !== language
          )

          const additonalLanguagesString =
            languagesArrayWithoutBaseLanguage.join(' ')

          return {
            updateValue: additonalLanguagesString,
            operationValue: languagesArrayWithoutBaseLanguage,
          }
        },
        condition: {
          update: {
            get settings() {
              return [GeneralSettingsBlocks.GENERAL.settings.BASE_LANGUAGE]
            },
            check: (updateValue, [baseLanguage]) => {
              const baseLanguageValue = baseLanguage.value
              let baseLanguageExist = false

              updateValue.map((language) => {
                if (language === baseLanguageValue) {
                  baseLanguageExist = true
                }
              })

              return {
                valid: baseLanguageExist,
                errorMessage: baseLanguageExist
                  ? ''
                  : 'Base language can not be removed.',
              }
            },
          },
        },
        selectOptions: (globalStates) => {
          const { languages } = globalStates[STATES.SITE_SETTINGS]

          return Object.keys(languages).map((key) => {
            return {
              value: key,
              label: languages
                ? languages[key].description
                : 'no data available',
            }
          })
        },
        formatDisplayValue: (additonalLanguages, globalStates) => {
          const { languages } = globalStates[STATES.SITE_SETTINGS]
          const { language } = globalStates[STATES.SURVEY].survey

          // Removing the base language from the list of additional languages if it exists to avoid duplication.
          // And sort alphabetically.
          additonalLanguages = additonalLanguages
            .replace(language, '')
            .trim()
            .split(' ')
            .sort()
            .join(' ')

          const selectedLanguages = language
            .concat(additonalLanguages ? ` ${additonalLanguages}` : '')
            .split(' ')

          const languagesOptions = selectedLanguages.map((language) => {
            return {
              value: language,
              label: languages
                ? languages[language]?.description
                : 'no data available',
            }
          })

          const sortedOptions = [
            languagesOptions[0],
            ...languagesOptions
              .slice(1)
              .sort((a, b) => a.label.localeCompare(b.label)),
          ]

          return sortedOptions
        },
        linkedSettingsHandler: {
          get linkedSettings() {
            return [GeneralSettingsBlocks.GENERAL.settings.BASE_LANGUAGE]
          },
          getUpdateValue: (currentValue, previousLinkedSettingValue) => {
            const languagesArray = currentValue
              .concat(` ${previousLinkedSettingValue}`)
              .split(' ')

            return languagesArray
          },
        },
        props: {
          id: 'multiLanguages',
          mainText: 'Survey languages',
          childComponent: Select,
          noPermissionDisabled: true,
          childOnNewLine: true,
          isMultiselect: true,
          dataTestId: 'additionalLanguages',
        },
      },
      BASE_LANGUAGE: {
        keyPath: 'language',
        selectOptions: (globalStates) => {
          const { languages } = globalStates[STATES.SITE_SETTINGS]
          const { survey } = globalStates[STATES.SURVEY]

          const surveyLanguages = survey.language
            .concat(` ${survey.additionalLanguages}`)
            .split(' ')

          return surveyLanguages.map((option) => {
            let languageOption = {
              value: option,
              label: languages
                ? languages[option]?.description
                : 'no data available',
            }

            return languageOption
          })
        },
        props: {
          id: 'baseLanguage',
          mainText: 'Base language',
          childComponent: Select,
          noPermissionDisabled: true,
          childOnNewLine: true,
          dataTestId: 'baseLanguage',
        },
      },
      OWNER_ID: {
        keyPath: 'ownerId',
        props: {
          id: 'ownerId',
          mainText: 'Survey owner',
          subText:
            'The survey owner has all survey permissions and can add more lower admin users to this survey site.',
          childComponent: Select,
          childOnNewLine: true,
          noPermissionDisabled: true,
        },
        selectOptions: (globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]

          return survey?.ownersList?.map((owners) => ({
            value: parseInt(owners.value),
            label: owners.label,
          }))
        },
      },
      ADMIN: {
        keyPath: 'admin',
        props: {
          id: 'admin',
          mainText: 'Administrator',
          dataTestId: '',
          childComponent: Input,
          childOnNewLine: true,
          noPermissionDisabled: true,
        },
      },
      ADMIN_EMAIL: {
        keyPath: 'adminEmail',
        props: {
          id: 'adminEmail',
          mainText: 'Administrator email address',
          dataTestId: '',
          childComponent: Input,
          childOnNewLine: true,
          noPermissionDisabled: true,
        },
      },
      BOUNCE_EMAIL: {
        keyPath: 'bounceEmail',
        props: {
          id: 'bounceEmail',
          mainText: 'Bounce email address',
          dataTestId: '',
          childComponent: Input,
          childOnNewLine: true,
          noPermissionDisabled: true,
        },
      },
      GSID: {
        keyPath: 'gsid',
        props: {
          id: 'gsid',
          mainText: 'Group',
          dataTestId: '',
          childComponent: Select,
          childOnNewLine: true,
          noPermissionDisabled: true,
        },
        selectOptions: (globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]

          return Object.keys(survey?.groupsList)?.map((key) => ({
            label: survey?.groupsList[parseInt(key)],
            value: parseInt(key),
          }))
        },
      },
      FORMAT: {
        keyPath: 'format',
        props: {
          id: 'format',
          mainText: 'Format',
          dataTestId: '',
          childComponent: Select,
          childOnNewLine: true,
          noPermissionDisabled: true,
        },
        selectOptions: (globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]

          return process.env.REACT_APP_DEV_MODE
            ? [
                {
                  label: `Inherit [ ${
                    formatOptions.find(
                      (option) => option.value === survey.formatInherited
                    )?.label
                  } ]`,
                  value: 'I',
                },
                ...formatOptions,
              ]
            : formatOptions
        },
      },
    },
  },
  THEME: {
    title: 'Theme',
    settings: {
      TEMPLATE: {
        component: Theme,
        keyPath: 'template',
        props: {
          id: 'template',
          mainText: 'Theme',
          dataTestId: '',
          childComponent: Select,
          childOnNewLine: true,
          noPermissionDisabled: true,
        },
        selectOptions: (globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]

          return process.env.REACT_APP_DEV_MODE
            ? [
                {
                  label: `Inherit [ ${survey.templateInherited} ]`,
                  value: 'inherit',
                },
                ...themeOptions,
              ]
            : themeOptions
        },
      },
    },
  },
}
