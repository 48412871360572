import {
  SURVEY_MENU_TITLES,
  TOOLTIP_MESSAGES,
  decodeHTMLEntities,
  getSiteUrl,
} from 'helpers'

export const presentationOptions = (surveyId, menuEntries) => [
  {
    label: menuEntries[SURVEY_MENU_TITLES.presentation]?.menuTitle,
    menu: SURVEY_MENU_TITLES.presentation,
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.themeOptions]?.menuTitle,
    menu: SURVEY_MENU_TITLES.themeOptions,
    redirect: getSiteUrl('/themeOptions/updateSurvey?surveyid=' + surveyId),
  },
]

export const settingsOptions = (surveyId, menuEntries) => [
  {
    label: menuEntries[SURVEY_MENU_TITLES.generalSettings]?.menuTitle,
    menu: SURVEY_MENU_TITLES.generalSettings,
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.dataSecurity]?.menuTitle,
    menu: SURVEY_MENU_TITLES.dataSecurity,
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.tokens]?.menuTitle,
    menu: SURVEY_MENU_TITLES.tokens,
  },
  {
    label: decodeHTMLEntities(
      menuEntries[SURVEY_MENU_TITLES.publication]?.menuTitle
    ),
    menu: SURVEY_MENU_TITLES.publication,
  },
  {
    label: decodeHTMLEntities(
      menuEntries[SURVEY_MENU_TITLES.notification]?.menuTitle
    ),
    menu: SURVEY_MENU_TITLES.notification,
  },
]

export const menuOptions = (surveyId, menuEntries, isSurveyActive) => [
  {
    label: menuEntries[SURVEY_MENU_TITLES.overview]?.menuTitle,
    menu: SURVEY_MENU_TITLES.overview,
    redirect: getSiteUrl(
      '/questionAdministration/listQuestions?surveyid=' + surveyId
    ),
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.tokens]?.menuTitle,
    menu: SURVEY_MENU_TITLES.tokens,
    redirect: getSiteUrl('/admin/tokens/sa/index/surveyid/' + surveyId),
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.emailTemplates]?.menuTitle,
    menu: SURVEY_MENU_TITLES.emailTemplates,
    redirect: getSiteUrl('/admin/emailtemplates/sa/index/surveyid/' + surveyId),
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.failedEmail]?.menuTitle,
    menu: SURVEY_MENU_TITLES.failedEmail,
    redirect: getSiteUrl('/failedEmail/index?surveyid=' + surveyId),
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.quotas]?.menuTitle,
    menu: SURVEY_MENU_TITLES.quotas,
    redirect: getSiteUrl('/quotas/index?surveyid=' + surveyId),
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.assessments]?.menuTitle,
    menu: SURVEY_MENU_TITLES.assessments,
    redirect: getSiteUrl('/assessment/index?surveyid=' + surveyId),
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.panelIntegration]?.menuTitle,
    menu: SURVEY_MENU_TITLES.panelIntegration,
    redirect: getSiteUrl(
      '/surveyAdministration/rendersidemenulink?surveyid=' +
        surveyId +
        '&subaction=panelintegration'
    ),
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.responses]?.menuTitle,
    menu: SURVEY_MENU_TITLES.responses,
    redirect: getSiteUrl('/responses/browse?surveyId=' + surveyId),
    disabled: !isSurveyActive,
    disabledTip: TOOLTIP_MESSAGES.SURVEY_NOT_ACTIVE,
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.statistics]?.menuTitle,
    menu: SURVEY_MENU_TITLES.statistics,
    redirect: getSiteUrl('/admin/statistics?sa=index&surveyid=' + surveyId),
    disabled: !isSurveyActive,
    disabledTip: TOOLTIP_MESSAGES.SURVEY_NOT_ACTIVE,
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.resources]?.menuTitle,
    menu: SURVEY_MENU_TITLES.resources,
    redirect: getSiteUrl(
      '/surveyAdministration/rendersidemenulink?surveyid=' +
        surveyId +
        '&subaction=resources'
    ),
  },
  {
    label: menuEntries[SURVEY_MENU_TITLES.plugins]?.menuTitle,
    menu: SURVEY_MENU_TITLES.plugins,
    redirect: getSiteUrl(
      '/surveyAdministration/rendersidemenulink?surveyid=' +
        surveyId +
        '&subaction=plugins'
    ),
  },
]
