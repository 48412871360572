import { useEffect, useMemo, useState } from 'react'

import { queryClient } from 'queryClient'
import { useAppState, useSurvey, useBuffer } from 'hooks'
import {
  STATES,
  createBufferOperation,
  Entities,
  errorToast,
  ignoreUpdate,
  getSettingValueFromSurvey,
  SURVEY_MENU_TITLES,
} from 'helpers'

import {
  GeneralSettingsBlocks,
  PrivacyPolicySettingsBlocks,
  PublicationAccessSettingsBlocks,
  ParticipantsSettingsBlocks,
  NotificationsDataSettingsBlocks,
  PresentationSettingsBlocks,
} from './Settings'
import { AdvancedOptionsSettings } from './AdvancedOptions'
import { SurveySettingBlock } from './SurveySettingBlock'

export const SurveySettings = ({ id }) => {
  const [currentOpenPanel = {}] = useAppState(STATES.CURRENT_OPEN_PANEL)
  const { survey, update } = useSurvey(id)

  const { operationsBuffer, addToBuffer } = useBuffer()

  const [surveyHash] = useAppState(STATES.SURVEY_HASH)
  const [helperSettings = {}, setHelperSettings] = useAppState(
    STATES.HELPER_SETTINGS
  )

  const [lastUpdatedSettings, setLastUpdatedSettings] = useState({
    value: '',
    previousValue: '',
    entity: '',
    setting: {},
  })

  const menu = useMemo(() => {
    if (currentOpenPanel.menu) {
      return currentOpenPanel.menu
    }
  }, [currentOpenPanel.panel, currentOpenPanel.menu])

  const globalStates = useMemo(() => {
    const statesArray = queryClient.getQueriesData().map((state) => {
      const key = state[0]
      const value = state[1]

      // incase of an appState the key is stored in the second index.
      return {
        [key[1] ? key[1] : key[0]]: value,
      }
    })

    // the reason why we want the states as an object is for easier access.
    // example: statesObject[STATES.SURVEY] is easier than statesArray.find((state) => state.key === STATES.SURVEY)
    const statesObject = Object.assign({}, ...statesArray)

    return statesObject
  }, [
    queryClient,
    operationsBuffer.bufferHash,
    surveyHash.updateHash,
    surveyHash.refetchHash,
  ])

  const settingsBlocksInfo = useMemo(() => {
    return {
      [SURVEY_MENU_TITLES.generalSettings]: Object.values(
        GeneralSettingsBlocks
      ),
      [SURVEY_MENU_TITLES.dataSecurity]: Object.values(
        PrivacyPolicySettingsBlocks
      ),
      [SURVEY_MENU_TITLES.publication]: Object.values(
        PublicationAccessSettingsBlocks
      ),
      [SURVEY_MENU_TITLES.notification]: Object.values(
        NotificationsDataSettingsBlocks
      ),
      [SURVEY_MENU_TITLES.tokens]: Object.values(ParticipantsSettingsBlocks),
      [SURVEY_MENU_TITLES.presentation]: Object.values(
        PresentationSettingsBlocks
      ),
    }
  }, [])

  useEffect(() => {
    const helperSettingsValue = {}
    // Loop through the blocks in surveySettings for the selected menu
    Object.entries(settingsBlocksInfo?.[menu] || {}).forEach(
      ([, blockSettings]) => {
        // Loop through each setting within the block
        Object.values(blockSettings.settings).forEach((setting) => {
          if (setting.helperSetting) {
            const settingValue = getSettingValueFromSurvey(survey, setting)
            const value =
              typeof setting.formatDisplayValue === 'function'
                ? setting.formatDisplayValue(settingValue, globalStates)
                : settingValue

            update({ [setting.keyPath]: value })
            if (typeof value !== 'undefined') {
              helperSettingsValue[setting.keyPath] = value
            }
          }
        })
      }
    )

    setHelperSettings(helperSettingsValue)
  }, [menu, surveyHash.refetchHash])

  // The purpose of this useEffect is to determine if linked settings needs to be updated, depending on the last updated setting.
  useEffect(() => {
    const setting = lastUpdatedSettings.setting
    const value = lastUpdatedSettings.value
    const previousValue = lastUpdatedSettings.previousValue

    Object.entries(settingsBlocksInfo?.[menu] || {}).forEach(
      ([, blockSettings]) => {
        Object.values(blockSettings.settings).forEach((linkedSetting) => {
          if (linkedSetting.linkedSettingsHandler) {
            const isSettingLinked =
              linkedSetting.linkedSettingsHandler.linkedSettings
                .map((linked) => linked.keyPath)
                .includes(setting.keyPath)

            if (!isSettingLinked) {
              return
            }

            const linkedSettingValue =
              linkedSetting.linkedSettingsHandler.getUpdateValue(
                getSettingValueFromSurvey(survey, linkedSetting),
                previousValue,
                value,
                setting,
                globalStates
              )
            if (linkedSettingValue !== ignoreUpdate) {
              updateSurveySetting(linkedSetting, linkedSettingValue, false)
            }
          }
        })
      }
    )
  }, [lastUpdatedSettings.value, lastUpdatedSettings.setting?.keyPath])

  const updateSurveySetting = (
    setting,
    value,
    markAsLastUpdatedSetting = true
  ) => {
    const updateConditionSettings = setting.condition?.update?.settings
    const currentValue = getSettingValueFromSurvey(survey, setting)
    const entity = setting.entity

    if (updateConditionSettings) {
      const settingsValue = updateConditionSettings.map((setting) => {
        return {
          setting,
          value: getSettingValueFromSurvey(survey, setting),
        }
      })

      const conditionResult = setting.condition.update.check(
        value,
        settingsValue
      )

      if (!conditionResult.valid) {
        errorToast(conditionResult.errorMessage)
        return
      }
    }

    const updateInfo = setting.formatUpdateValue
      ? setting.formatUpdateValue(value, globalStates)
      : { updateValue: value, operationValue: value }

    // Getting the last string after '.' if it exists
    // for example: languageSettings.legalNotice ==Output=> legalNotice
    // or use the key that comes from the formatUpdateValue if it exists
    const updateKey = updateInfo.updateValueKey
      ? updateInfo.updateValueKey
      : setting?.keyPath.split('.').pop()

    update({ [updateKey]: updateInfo.updateValue })

    if (setting?.helperSetting) {
      setHelperSettings({
        ...helperSettings,
        [setting.keyPath]: updateInfo.updateValue,
      })
    } else {
      const operationKey = updateInfo.updateOperationKey
        ? updateInfo.updateOperationKey
        : setting?.keyPath.split('.').pop()

      const operationProps = {
        [operationKey]: updateInfo.operationValue,
      }

      let operation
      const operationEntity =
        entity === Entities.languageSetting
          ? Entities.languageSetting
          : Entities.survey

      operation = createBufferOperation(id)
        // eslint-disable-next-line no-unexpected-multiline
        [operationEntity]()
        .update(operationProps)

      addToBuffer(operation)
    }

    if (markAsLastUpdatedSetting) {
      setLastUpdatedSettings({
        value: updateInfo.updateValue,
        previousValue: currentValue,
        setting,
        entity,
      })
    }
  }

  const updateValue = (value, setting) => {
    updateSurveySetting(setting, value)
  }

  return (
    <div className="survey-settings-panel mt-5">
      {Object.entries(settingsBlocksInfo?.[menu] || {}).map(
        ([blockKey, blockSettingsInfo], blockIdx) => {
          return (
            <SurveySettingBlock
              blockSettingsInfo={blockSettingsInfo}
              currentOpenPanel={currentOpenPanel}
              globalStates={globalStates}
              helperSettings={helperSettings}
              survey={survey}
              updateValue={updateValue}
              key={`${blockIdx}-${blockKey}`}
            />
          )
        }
      )}

      {menu === 'advancedOptions' && <AdvancedOptionsSettings surveyId={id} />}
    </div>
  )
}
