import { useEffect, useMemo, useState } from 'react'
import ReactContentEditable from 'react-contenteditable'

import { useAppState } from 'hooks'
import {
  RemoveHTMLTagsInString,
  ReplaceQuestionCodesWithAnswers,
  STATES,
} from 'helpers'

export const ContentEditable = ({
  onFocus,
  onBlur,
  disabled,
  handleOnChange,
  contentEditableRef,
  value,
  placeholder,
  replaceVariables,
  focus = false,
  onKeyDown,
  testId = '',
}) => {
  const [codeToQuestion] = useAppState(STATES.CODE_TO_QUESTION, {})
  const [questionTitle, setQuestionTitle] = useState(
    RemoveHTMLTagsInString(value)
  )
  const [isFocused, setIsFocused] = useState(false)

  const valueIsEmpty = useMemo(() => {
    return RemoveHTMLTagsInString(value).length === 0
  }, [value])

  const onChange = (value) => {
    value = RemoveHTMLTagsInString(value, ['br', 'div'])
    setQuestionTitle(value)
    handleOnChange(value)
  }

  const handleFocus = () => {
    setIsFocused(true)
    onFocus()
  }

  const handleBlur = () => {
    setIsFocused(false)
    onBlur()
  }

  useEffect(() => {
    if (isFocused) {
      return
    }

    const title = replaceVariables
      ? ReplaceQuestionCodesWithAnswers(value, codeToQuestion)
      : value
    const titleWithoutHTML = RemoveHTMLTagsInString(title)

    setQuestionTitle(titleWithoutHTML ? title : '')
  }, [value, codeToQuestion, isFocused])

  return (
    <ReactContentEditable
      onFocus={handleFocus}
      onBlur={handleBlur}
      innerRef={contentEditableRef}
      className={`content-editable`}
      disabled={disabled}
      html={questionTitle}
      onChange={({ target: { value } }) => onChange(value)}
      data-placeholder={valueIsEmpty && placeholder}
      autoFocus={focus}
      onKeyDown={onKeyDown}
      data-testid={testId}
    />
  )
}
