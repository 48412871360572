//this is used for getting the value of object property of survey
//for example survey: { defaultLanguage: {policyMessage:"..."} }
const reduceValue = (obj, keys) => {
  return keys.reduce(
    (acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : undefined),
    obj
  )
}

export const getSettingValueFromSurvey = (survey, setting, language) => {
  if (!language) {
    language = survey.language
  }

  return setting?.keyPath.split('.')?.length > 1
    ? (reduceValue(survey, [
        setting?.keyPath.split('.')[0],
        language,
        setting?.keyPath.split('.')[1],
      ]) ?? '')
    : survey?.[setting?.keyPath]
}
