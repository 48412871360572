import classNames from 'classnames'

import { Button, TooltipContainer } from 'components'

export const ButtonUpgrade = ({ className = '' }) => {
  const queryString = '?backurl=' + encodeURIComponent(window.location.href)
  const upgradeUrl = process.env.REACT_APP_UPGRADE_URL
    ? process.env.REACT_APP_UPGRADE_URL
    : 'https://account.limesurvey.org/pricing'
  return (
    <TooltipContainer placement="bottom" showTip={true} tip="Upgrade plan">
      <Button
        href={upgradeUrl + queryString}
        variant="info"
        className={classNames('upgrade-button', className)}
        onClick={(e) => {
          e.preventDefault()
          window.open(upgradeUrl + queryString, '_blank')
        }}
        target="_blank"
      >
        Upgrade
      </Button>
    </TooltipContainer>
  )
}
