export const EntitiesType = {
  question: 'question',
  group: 'group',
  survey: 'survey',
  groupName: 'groupName',
  answer: 'answer',
  subquestion: 'subquestion',
  welcomeScreen: 'welcomeScreen',
  endScreen: 'endScreen',
}
