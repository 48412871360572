import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { OverlayTrigger } from 'react-bootstrap'

import { TooltipContainer } from 'components/TooltipContainer/TooltipContainer'
import {
  GetInvalidSurveyObjects,
  errorToast,
  createBufferOperation,
  STATES,
  Toast,
  TOOLTIP_MESSAGES,
} from 'helpers'
import { useAppState, useBuffer, useFocused } from 'hooks'
import { Button } from 'components'
import { CheckIcon, StopIcon } from 'components/icons'
import { PublishMenu } from './PublishMenu'

export const PublishSettings = ({
  survey,
  update,
  operationsLength = 0,
  className = '',
}) => {
  const { setFocused } = useFocused()
  const [surveyPublishIsToggled, setSurveyPublishIsToggled] = useState(false)
  const [isSurveyShareMenuOpen, setIsSurveyShareMenuOpen] = useAppState(
    STATES.IS_SURVEY_SHARE_MENU_OPEN,
    false
  )
  const { addToBuffer } = useBuffer()
  const [isPatchSurveyRunning] = useAppState(STATES.IS_PATCH_SURVEY_RUNNING)
  const [numberOfQuestions] = useAppState(STATES.NUMBER_OF_QUESTIONS)
  const [isSurveyActive, setIsSurveyActive] = useAppState(
    STATES.IS_SURVEY_ACTIVE,
    false
  )
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )

  const togglePublish = (isActivated) => {
    const invalidSurveyObjects = GetInvalidSurveyObjects(survey)

    const groupKeys = Object.keys(
      invalidSurveyObjects?.gid ? invalidSurveyObjects?.gid : {}
    )
    const questionKeys = Object.keys(
      invalidSurveyObjects?.qid ? invalidSurveyObjects?.qid : {}
    )

    if (groupKeys.length) {
      const firstGroup = invalidSurveyObjects.gid[groupKeys[0]]
      setFocused(
        {
          ...survey.questionGroups[firstGroup.groupIndex],
        },
        firstGroup.groupIndex
      )
    }

    if (questionKeys.length) {
      const firstQuestion = invalidSurveyObjects.qid[questionKeys[0]]
      setFocused(
        {
          ...survey.questionGroups[firstQuestion.groupIndex].questions[
            firstQuestion.questionIndex
          ],
        },
        firstQuestion.groupIndex,
        firstQuestion.questionIndex
      )
    }

    if (questionKeys.length || groupKeys.length) {
      errorToast(
        'Invalid survey questions. Please resolve the errors and try again.'
      )
      return
    }

    const operation = createBufferOperation(survey.sid).surveyStatus().update({
      anonymized: false,
      activate: isActivated,
    })

    addToBuffer(operation)
    update({ active: isActivated })
    setIsSurveyActive(isActivated)
    setTimeout(() => {
      setSurveyPublishIsToggled(true)
    }, 0)
  }

  useEffect(() => {
    if (isPatchSurveyRunning || !surveyPublishIsToggled) {
      return
    }

    const surveyIsActive = survey.active

    Toast({
      message:
        'Your survey has been ' +
        (surveyIsActive ? 'activated.' : 'deactivated.'),
      position: 'bottom-center',
      className: 'success-toast',
      leftIcon: (
        <CheckIcon
          className={`fill-current text-success rounded-circle bg-dark me-1`}
        />
      ),
    })

    setIsSurveyShareMenuOpen(isSurveyActive)
    setSurveyPublishIsToggled(false)
  }, [isPatchSurveyRunning])

  let activateTip

  if (isSurveyActive) {
    activateTip =
      'When deactivated, responses will be archived. ' +
      'You will then be able to add or delete questions, ' +
      ' question groups, and settings again.'
  } else if (numberOfQuestions === 0) {
    activateTip = 'You must have at least one question.'
  } else if (operationsLength) {
    activateTip = 'Waiting for changes to be saved...'
  } else if (!hasSurveyUpdatePermission) {
    activateTip = TOOLTIP_MESSAGES.NO_PERMISSION
  } else {
    activateTip =
      'When activated, you will not be able to add or delete questions, ' +
      'question groups, or sub-questions. ' +
      'However, you can still edit.'
  }

  return (
    <OverlayTrigger
      trigger="click"
      overlay={
        <PublishMenu
          survey={survey}
          setIsSurveyShareMenuOpen={setIsSurveyShareMenuOpen}
        />
      }
      placement="bottom"
      show={isSurveyShareMenuOpen}
      onToggle={() => setIsSurveyShareMenuOpen(false)}
      rootClose
    >
      <span data-testid="publish-settings">
        <TooltipContainer placement="bottom" showTip={true} tip={activateTip}>
          <Button
            variant={isSurveyActive ? 'danger' : 'success'}
            className={classNames(
              'publish-button align-items-center d-flex ml-auto me-2',
              className
            )}
            onClick={() => {
              togglePublish(!isSurveyActive)
            }}
            disabled={
              surveyPublishIsToggled ||
              operationsLength ||
              numberOfQuestions === 0 ||
              !hasSurveyUpdatePermission
            }
          >
            {surveyPublishIsToggled ? (
              <div style={{ width: 24, height: 24 }} className="loader"></div>
            ) : (
              <>
                <div
                  className="d-flex align-items-center"
                  style={{ width: '20px' }}
                >
                  {isSurveyActive ? (
                    <StopIcon className={`fill-current text-white me-1`} />
                  ) : (
                    <CheckIcon className={`fill-current text-white me-1`} />
                  )}
                </div>
                <p className="m-0 text-white">
                  {isSurveyActive ? 'Deactivate' : 'Activate'}
                </p>
              </>
            )}
          </Button>
        </TooltipContainer>
      </span>
    </OverlayTrigger>
  )
}
