import React from 'react'
import classNames from 'classnames'
import Fade from 'react-bootstrap/Fade'

export const SideBar = ({
  testId = '',
  visible = false,
  className = '',
  children,
}) => {
  return (
    <Fade
      in={typeof visible === 'boolean' ? visible : false}
      onEntered={() => null}
      onExiting={() => null}
    >
      <div data-testid={testId} className={classNames('sidebar', className)}>
        {children}
      </div>
    </Fade>
  )
}
