import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ReactSelect from 'react-select'
import classNames from 'classnames'

import { useAppState } from '../../../hooks'
import { STATES, TOOLTIP_MESSAGES } from '../../../helpers'
import { TooltipContainer } from '../../TooltipContainer/TooltipContainer'

export const Select = ({
  labelText,
  options = [],
  className = '',
  dataTestId = '',
  onChange = () => {},
  update = () => {},
  value,
  activeDisabled = false,
  noPermissionDisabled = false,
  noAccessDisabled = false,
  onMenuClose = () => {},
  onMenuOpen = () => {},
  isMultiselect = false,
  defaultValue = options[0],
  menuStyle = {},
  placeholder = 'Please choose...',
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const disabled =
    (isSurveyActive && activeDisabled) ||
    (!hasSurveyUpdatePermission && noPermissionDisabled) ||
    noAccessDisabled
  const toolTip =
    isSurveyActive && activeDisabled
      ? TOOLTIP_MESSAGES.ACTIVE_DISABLED
      : TOOLTIP_MESSAGES.NO_PERMISSION

  if (value && !isMultiselect && options?.length) {
    value = options.find((option) => option.value === value)
  }

  const handleOnChange = (selectedOption) => {
    onChange(selectedOption)

    if (isMultiselect) {
      const updateValue = selectedOption.map((option) => option.value)
      update(updateValue)
    } else {
      update(selectedOption.value)
    }
  }

  const handleOnMenuOpen = () => {
    setIsOpen(true)
    onMenuOpen()
  }

  const handleOnMenuClose = () => {
    setIsOpen(false)
    onMenuClose()
  }

  return (
    <div
      className={classNames(`select-component ${className}`, {
        'select-component-open': isOpen,
      })}
      data-testid={dataTestId}
    >
      {labelText && <Form.Label htmlFor="select">{labelText}</Form.Label>}
      <TooltipContainer tip={toolTip} showTip={disabled}>
        <ReactSelect
          classNames={{
            control: () => 'select',
          }}
          defaultValue={defaultValue}
          value={value}
          onChange={handleOnChange}
          options={options}
          placeholder={placeholder}
          isMulti={isMultiselect}
          components={{
            IndicatorSeparator: () => null,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#8146F6',
            },
          })}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: '#6E748C',
              minWidth: 'fit-content',
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              'borderRadius': '4px',
              'borderWidth': '2px',
              'borderColor': ' #6E748C',
              'boxShadow': 'none',
              'fontWeight': 400,
              'fontSize': '0.9975rem',
              '&:hover': {
                borderColor: ' #6E748C',
              },
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              width: '100%',
              minWidth: 'min-content',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              ...menuStyle,
            }),
          }}
          isDisabled={disabled}
          isClearable={false}
          onMenuOpen={handleOnMenuOpen}
          onMenuClose={handleOnMenuClose}
        />
      </TooltipContainer>
    </div>
  )
}
