import { getSettingValueFromSurvey, STATES } from 'helpers'

import { ToggleButtons, Input } from 'components/UIComponents'

export const ParticipantsSettingsBlocks = {
  PARTICIPANTS: {
    title: 'Participant settings',
    settings: {
      ANONYMIZED: {
        keyPath: 'anonymized',
        props: {
          id: 'anonymize-responses',
          mainText: 'Anonymized responses',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          activeDisabled: true,
          noPermissionDisabled: true,
        },
      },
      ENABLE_PARTICIPANT_BASED_RESPONSE_PERSISTENCE: {
        keyPath: 'tokenAnswersPersistence',
        props: {
          id: 'token-answer-persistence',
          mainText: 'Enable participant-based response persistence',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      ALLOWED_IT_AFTER_COMPLETION: {
        keyPath: 'allowedItAfterCompletion',
        props: {
          id: 'allow-multiple-responses',
          mainText: 'Allow multiple responses with the same access code',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      ALLOWED_PUBLIC_REGISTRATION: {
        keyPath: 'allowRegister',
        props: {
          id: 'allow-public-registration',
          mainText: 'Allow public registration',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      HTML_EMAIL: {
        keyPath: 'htmlEmail',
        props: {
          id: 'html-email',
          mainText: 'Use HTML format for participant emails',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      SEND_CONFIRMATION: {
        keyPath: 'sendConfirmation',
        props: {
          id: 'send-confirmation',
          mainText: 'Send confirmation emails',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
      },
      SET_ACCESS_CODE_LENGTH: {
        keyPath: 'setAccessCodeLength',
        helperSetting: true,
        props: {
          id: 'setAccessCodeLength',
          mainText: 'Set access code length',
          childComponent: ToggleButtons,
          toggleOptions: [
            { name: 'On', value: true },
            { name: 'Off', value: false },
          ],
          noPermissionDisabled: true,
        },
        formatDisplayValue: (value, globalStates) => {
          const { survey } = globalStates[STATES.SURVEY]
          const tokenLengthValue = getSettingValueFromSurvey(
            survey,
            ParticipantsSettingsBlocks.PARTICIPANTS.settings.TOKEN_LENGTH
          )
          return -1 != tokenLengthValue
        },
      },
      TOKEN_LENGTH: {
        keyPath: 'tokenLength',
        linkedSettingsHandler: {
          get linkedSettings() {
            return [
              ParticipantsSettingsBlocks.PARTICIPANTS.settings
                .SET_ACCESS_CODE_LENGTH,
            ]
          },
          getUpdateValue: (
            value,
            previousLinkedSettingValue,
            currentLinkedSettingValue
          ) => {
            if (!currentLinkedSettingValue) {
              return -1
            } else if (value < 0) {
              return 15
            }
            return value
          },
        },
        condition: {
          update: {
            get settings() {
              return [
                ParticipantsSettingsBlocks.PARTICIPANTS.settings.TOKEN_LENGTH,
                ParticipantsSettingsBlocks.PARTICIPANTS.settings
                  .SET_ACCESS_CODE_LENGTH,
              ]
            },
            check: (updateValue, [, isOn]) => {
              const valid = !isOn.value || updateValue > 4

              return {
                valid,
                errorMessage: valid ? '' : 'Token length must be at least 5',
              }
            },
          },
          render: {
            get settings() {
              return [
                ParticipantsSettingsBlocks.PARTICIPANTS.settings
                  .SET_ACCESS_CODE_LENGTH,
              ]
            },
            check: ([setAccessCodeLength]) => {
              return setAccessCodeLength.value
            },
          },
        },
        props: {
          id: 'token-length',
          type: 'number',
          mainText: 'Access code length',
          childComponent: Input,
          childOnNewLine: true,
        },
      },
    },
  },
}
