import { Input, Select, ToggleButtons } from 'components/UIComponents'

export const INPUT_ATTRIBUTES = {
  DATE_FORMAT: {
    component: Select,
    attributePath: 'attributes.date_format',
    props: {
      labelText: 'Date/Time Format',
      dataTestId: 'date-time-format',
      options: [
        {
          label: 'YYYY-MM-DD',
          value: 'YYYY-MM-DD',
        },
        {
          label: 'YYYY/MM/DD',
          value: 'YYYY/MM/DD',
        },
        {
          label: 'MM-DD-YYYY',
          value: 'MM-DD-YYYY',
        },
        {
          label: 'MM/DD/YYYY',
          value: 'MM/DD/YYYY',
        },
        {
          label: 'DD-MM-YYYY',
          value: 'DD-MM-YYYY',
        },
        {
          label: 'DD/MM/YYYY',
          value: 'DD/MM/YYYY',
        },
      ],
    },
  },
  MINUTE_STEP_INTERVAL: {
    component: Input,
    attributePath: 'attributes.dropdown_dates_minute_step',
    props: {
      labelText: 'Minute step interval',
      dataTestId: 'minute-step-interval',
      max: 30,
      min: 0,
      type: 'number',
    },
  },
  MINIMUM_VALUE: {
    component: Input,
    attributePath: 'attributes.min_num_value_n',
    props: {
      labelText: 'Minimum value',
      dataTestId: 'minimum-value',
    },
  },
  EQUAL_SUM_VALUE: {
    component: Input,
    attributePath: 'attributes.equals_num_value',
    props: {
      labelText: 'Equals sum value',
    },
  },
  INTEGER_ONLY: {
    component: ToggleButtons,
    attributePath: 'attributes.num_value_int_only',
    props: {
      labelText: 'Integer only',
      id: 'integer-only',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  MAXIMUM_SUM_VALUE: {
    component: Input,
    attributePath: 'attributes.max_num_value',
    props: {
      labelText: 'Maximum sum value',
    },
  },
  MINIMUM_SUM_VALUE: {
    component: Input,
    attributePath: 'attributes.min_num_value',
    props: {
      labelText: 'Minimum sum value',
    },
  },
  MAXIMUM_VALUE: {
    component: Input,
    attributePath: 'attributes.max_num_value_n',
    props: {
      labelText: 'Maximum value',
      dataTestId: 'maximum-value',
    },
  },
  VALUE_RANGE_ALLOWS_MISSING: {
    component: ToggleButtons,
    attributePath: 'attributes.value_range_allows_missing',
    props: {
      labelText: 'Value range allows missing',
      id: 'value-range-allows-missing',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
}
