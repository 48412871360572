import { Input, Select, ToggleButtons } from 'components/UIComponents'
import { COMMENTED_CHECKBOX_OPTIONS } from 'helpers'

export const LOGIC_ATTRIBUTES = {
  MINIMUM_ANSWERS: {
    component: Input,
    attributePath: 'attributes.min_answers',
    props: {
      labelText: 'Minimum answers',
      dataTestId: 'minimum-answers',
    },
  },
  MAXIMUM_ANSWERS: {
    component: Input,
    attributePath: 'attributes.max_answers',
    props: {
      labelText: 'Maximum answers',
      dataTestId: 'maximum-answers',
    },
  },
  COMMENT_ONLY_WHEN: {
    component: Select,
    attributePath: 'attributes.commented_checkbox',
    props: {
      labelText: 'Comment only when',
      dataTestId: 'comment-only-when',
      options: [
        {
          label: COMMENTED_CHECKBOX_OPTIONS.CHECKED.label,
          value: COMMENTED_CHECKBOX_OPTIONS.CHECKED.value,
        },
        {
          label: COMMENTED_CHECKBOX_OPTIONS.ALWAYS.label,
          value: COMMENTED_CHECKBOX_OPTIONS.ALWAYS.value,
        },

        {
          label: COMMENTED_CHECKBOX_OPTIONS.UNCHECKED.label,
          value: COMMENTED_CHECKBOX_OPTIONS.UNCHECKED.value,
        },
      ],
    },
  },
  EQUATION: {
    component: Input,
    attributePath: 'attributes.equation',
    props: {
      labelText: 'Equation',
    },
  },
  ARRAY_FILTER_EXCLUSION: {
    component: Input,
    attributePath: 'attributes.array_filter_exclude',
    props: {
      labelText: 'Array filter exclusion',
      dataTestId: 'array-filter-exclusion',
    },
  },
  ARRAY_FILTER: {
    component: Input,
    attributePath: 'attributes.array_filter',
    props: {
      labelText: 'Array filter',
      dataTestId: 'array-filter',
    },
  },
  ARRAY_FILTER_STYLE: {
    component: ToggleButtons,
    attributePath: 'attributes.array_filter_style',
    props: {
      labelText: 'Array filter style',
      dataTestId: 'array-filter-style',
      toggleOptions: [
        { name: 'Hidden', value: '0' },
        { name: 'Disabled', value: '1' },
      ],
    },
  },
  NUMBERS_ONLY_FOR_OTHER: {
    component: ToggleButtons,
    attributePath: 'attributes.other_numbers_only',
    props: {
      labelText: "Numbers only for 'Other'",
      id: 'numbers-only-for-other',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  REMOVE_TEXT_OR_UNCHECK_CHECKBOX_AUTOMATICALLY: {
    component: ToggleButtons,
    attributePath: 'attributes.commented_checkbox_auto',
    props: {
      labelText: 'Remove text or uncheck checkbox automatically',
      id: 'remove-text-or-uncheck-checkbox-automatically',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  // todo: check this if it should be of type number.
  ASSESSMENT_VALUE: {
    component: Input,
    attributePath: 'attributes.assessment_value',
    props: {
      labelText: 'Assessment value',
      dataTestId: 'assessment-value',
    },
  },
  EXCLUSIVE_OPTIONS: {
    component: Input,
    attributePath: 'attributes.exclude_all_others',
    props: {
      labelText: 'Exclusive option',
      dataTestId: 'exclusive-option',
    },
  },
  RANDOMIZATION_GROUP_NAME: {
    component: Input,
    attributePath: 'attributes.random_group',
    props: {
      labelText: 'Randomization group name',
      dataTestId: 'randomization-group-name',
    },
  },
  QUESTION_VALIDATION_EQUATION: {
    component: Input,
    attributePath: 'attributes.em_validation_q',
    props: {
      labelText: 'Question validation equation',
    },
  },
  COMMENT_MANDATORY: {
    component: ToggleButtons,
    attributePath: 'attributes.other_comment_mandatory',
    props: {
      labelText: "'Other:' comment mandatory",
      id: 'comment-mandatory',
      toggleOptions: [
        { name: 'On', value: '1' },
        { name: 'Off', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SUBQUESTION_VALIDATION_EQUATION: {
    component: Input,
    attributePath: 'attributes.em_validation_sq',
    props: {
      labelText: 'Sub-question validation equation',
    },
  },
  SUBQUESTION_VALIDATION_TIP: {
    component: Input,
    attributePath: 'attributes.em_validation_sq_tip',
    props: {
      labelText: 'Question validation tip',
    },
  },
  QUESTION_VALIDATION_TIP: {
    component: Input,
    attributePath: 'attributes.em_validation_q_tip',
    languageBased: true,
    props: {
      labelText: 'Question validation Tip',
    },
  },
  MAXIMUM_COLUMNS_FOR_ANSWERS: {
    component: Input,
    attributePath: 'attributes.max_subquestions',
    props: {
      labelText: 'Maximum columns for answers',
      dataTestId: 'maximum-columns-for-answers',
      type: 'number',
    },
  },
}
