import classNames from 'classnames'
import { decodeHTMLEntities, getSettingValueFromSurvey } from 'helpers'
import { SurveySetting } from './SurveySetting'

export const SurveySettingBlock = ({
  blockSettingsInfo,
  currentOpenPanel,
  globalStates,
  helperSettings,
  survey: { active = false, sid = '' },
  survey,
  updateValue,
}) => {
  return (
    <div className="settings-block p-4 mb-4 bg-white">
      <h5>
        {decodeHTMLEntities(blockSettingsInfo.title || currentOpenPanel.label)}
      </h5>
      {Object.entries(blockSettingsInfo.settings).map(
        ([settingKey, setting]) => {
          if (settingKey === 'blockTitle') return null // Skip blockTitle in settings loop

          // Check for access (if the settings menu item is listed in accessibleSettingItems array)
          const noAccessDisabled = false
          const renderCondition = setting.condition?.render

          if (renderCondition) {
            const renderSettings = renderCondition.settings.map(
              (condSetting) => {
                const value = condSetting.helperSetting
                  ? helperSettings[condSetting.keyPath]
                  : getSettingValueFromSurvey(survey, condSetting)

                return {
                  setting: condSetting,
                  value,
                }
              }
            )
            if (!renderCondition.check(renderSettings, globalStates)) {
              return null
            }
          }

          const value = setting.helperSetting
            ? helperSettings[setting.keyPath]
            : getSettingValueFromSurvey(survey, setting)

          const formattedDisplayValue = setting.formatDisplayValue
            ? setting.formatDisplayValue(value, globalStates)
            : value

          setting.component = setting.component
            ? setting.component
            : SurveySetting

          return (
            <div
              key={settingKey}
              className={classNames('mb-2 survey-setting', {
                'survey-border-top': !setting.noBorderTop,
              })}
            >
              <setting.component
                options={
                  setting.selectOptions
                    ? setting.selectOptions(globalStates)
                    : []
                }
                {...setting.props}
                value={formattedDisplayValue}
                defaultValue={formattedDisplayValue}
                update={(value, _setting = setting) =>
                  updateValue(value, _setting)
                }
                activeDisabled={active && setting.props.activeDisabled}
                noPermissionDisabled={setting.props.noPermissionDisabled}
                noAccessDisabled={noAccessDisabled}
                setting={setting}
                surveyId={sid}
              />
            </div>
          )
        }
      )}
    </div>
  )
}
