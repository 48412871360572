import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import Button from 'react-bootstrap/Button'

import { ContentEditor } from 'components/UIComponents'
import { ArrowDownIcon } from 'components/icons'
import { TooltipContainer } from 'components/TooltipContainer/TooltipContainer'

export const SideBarRow = ({
  icon,
  title,
  titlePlaceholder,
  code,
  meatballButton,
  children,
  style,
  testId = '',
  provided = {},
  onTitleClick = () => {},
  onRowClick = () => {},
  showMeatballButton,
  showQuestionCode = false,
  isQuestionGroup,
  isOpen: _isOpen = false,
  className = '',
}) => {
  const [isOpen, setOpen] = useState(_isOpen)

  useEffect(() => {
    if (!isOpen) {
      setOpen(_isOpen)
    }
  }, [_isOpen])

  return (
    <div onClick={onRowClick} data-testid={testId}>
      <div
        className={`sidebar-row ps-1 ${className}`}
        style={{
          ...style,
        }}
      >
        <div
          style={{
            cursor: provided.dragHandleProps ? 'grab' : 'pointer',
          }}
          className={classNames(
            'sidebar-row-title-container d-flex align-items-center'
          )}
          {...provided.dragHandleProps}
        >
          <Button
            data-testid={`sidebar-row-toggler-button`}
            variant="link"
            onClick={() => setOpen(!isOpen)}
            className={classNames('p-0', {
              'rotate-270': !isOpen,
              'd-none': !isQuestionGroup,
            })}
          >
            <ArrowDownIcon />
          </Button>
          {typeof icon === 'string' ? (
            <img
              className={classNames({
                'question-group': !code,
              })}
              src={icon}
              alt="sidebar row item icon"
            />
          ) : (
            icon
          )}
          <ContentEditor
            disabled={true}
            placeholder={titlePlaceholder}
            value={title}
            className={classNames('sidebar-row-title', {
              'question-group': !code,
              'question-code': showQuestionCode,
            })}
            style={{
              width: showQuestionCode ? '120px' : '180px',
              marginLeft: '10px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            onClick={onTitleClick}
          />
        </div>
        <div
          className={classNames(
            'd-flex',
            'flex-grow-1',
            'align-items-center',
            'justify-content-end'
          )}
        >
          <div>
            {showQuestionCode && (
              // when the length is more than 5 then its shorted with dots
              <TooltipContainer tip={code} showTip={code?.length > 5}>
                <div
                  className="sidebar-row-tag mx-1 bg-white"
                  style={{
                    fontSize: '10px',
                    margin: '6px 2px ',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  #{code}
                </div>
              </TooltipContainer>
            )}
          </div>
          <span
            className={classNames('sidebar-meatball-menu m-1', {
              'opacity-100': showMeatballButton,
            })}
          >
            {meatballButton}
          </span>
        </div>
      </div>
      {isOpen && <div style={{ paddingLeft: '18px' }}>{children}</div>}
    </div>
  )
}
