import { Input, Select, ToggleButtons } from 'components/UIComponents'

export const SLIDER_ATTRIBUTES = {
  USE_SLIDER_LAYOUT: {
    component: ToggleButtons,
    attributePath: 'attributes.slider_layout',
    props: {
      id: 'use-slider-layout',
      labelText: 'Use Slider Layout',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  ORIENTATION: {
    component: ToggleButtons,
    attributePath: 'attributes.slider_orientation',
    props: {
      id: 'orientation',
      labelText: 'Orientation',
      toggleOptions: [
        { name: 'Horizontal', value: '0' },
        { name: 'Vertical', value: '1' },
      ],
      defaultValue: '0',
    },
  },
  SLIDER_MINIMUM_VALUE: {
    component: Input,
    attributePath: 'attributes.slider_min',
    props: {
      id: 'slider-minimum-value',
      labelText: 'Slider minimum value',
      type: 'number',
    },
  },
  SLIDER_MAXIMUM_VALUE: {
    component: Input,
    attributePath: 'attributes.slider_max',
    props: {
      id: 'slider-maximum-value',
      labelText: 'Slider maximum value',
      type: 'number',
    },
  },
  SLIDER_ACCURACY: {
    component: Input,
    attributePath: 'attributes.slider_accuracy',
    props: {
      id: 'slider-accuracy',
      labelText: 'Step value',
      value: 1,
      type: 'number',
    },
  },
  DISPLAY_SLIDER_MIN_AND_MAX_VALUE: {
    component: ToggleButtons,
    attributePath: 'attributes.slider_showminmax',
    props: {
      id: 'display-slider-min-and-max-value',
      labelText: 'Display min and max value',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SLIDER_LEFT_RIGHT_TEXT_SEPARATOR: {
    component: Input,
    attributePath: 'attributes.slider_separator',
    props: {
      id: 'slider-left-right-text-separator',
      labelText: 'Slider left/right text separator',
    },
  },
  SLIDER_STARTS_AT_THE_MIDDLE_POSITION: {
    component: Select,
    attributePath: 'attributes.slider_middlestart',
    props: {
      id: 'slider-starts-at-the-middle-position',
      labelText: 'Start value',
      options: [
        { label: 'Center position', value: '1' },
        { label: 'Initial value', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  SLIDER_INITIAL_VALUE: {
    component: Input,
    attributePath: 'attributes.slider_default',
    props: {
      id: 'slider-initial-value',
      labelText: 'Slider initial value',
      type: 'number',
    },
  },
  SLIDER_INITIAL_VALUE_SET_AT_STARTS: {
    component: ToggleButtons,
    attributePath: 'attributes.slider_default_set',
    props: {
      id: 'slider-initial-value-set-at-starts',
      labelText: 'Use initial value as answer',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '1',
    },
  },
  ALLOW_SLIDER_RESET: {
    component: ToggleButtons,
    attributePath: 'attributes.slider_reset',
    props: {
      id: 'allow-slider-reset',
      labelText: 'Allow slider reset',
      toggleOptions: [
        { name: 'Yes', value: '1' },
        { name: 'No', value: '0' },
      ],
      defaultValue: '0',
    },
  },
  REVERSE_THE_SLIDER_DIRECTION: {
    component: ToggleButtons,
    attributePath: 'attributes.slider_reversed',
    props: {
      name: 'reverse-the-slider-direction',
      id: 'reverse-the-slider-direction',
      labelText: 'Slider direction',
      toggleOptions: [
        { name: 'Default', value: '0' },
        { name: 'Reverse', value: '1' },
      ],
      defaultValue: '0',
    },
  },
  HANDLE_SHAPE: {
    component: Select,
    attributePath: 'attributes.slider_handle',
    props: {
      id: 'handle-shape',
      labelText: 'Handle shape',
      options: [
        {
          label: 'Circle',
          value: '0',
        },
        {
          label: 'Square',
          value: '1',
        },
        {
          label: 'Triangle',
          value: '2',
        },
        {
          label: 'Custom',
          value: '3',
        },
      ],
    },
  },
  CUSTOM_HANDLE_UNICODE_CODE: {
    component: Input,
    attributePath: 'attributes.slider_custom_handle',
    props: {
      id: 'custom-handle-unicode-code',
      labelText: 'Custom handle unicode code',
      dataTestId: 'custom-handle-unicode-code',
    },
  },
}
