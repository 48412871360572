import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { ToggleButton, ButtonGroup, Form } from 'react-bootstrap'

import { useAppState } from 'hooks'
import { STATES, TOOLTIP_MESSAGES } from 'helpers'
import { TooltipContainer } from 'components'

const twoConditions = [
  { name: 'On', value: true },
  { name: 'Off', value: false },
]

const threeConditions = [
  { name: 'Yes', value: '1' },
  { name: 'Maybe', value: '0' },
  { name: 'No', value: '-1' },
]

// TODO this component will replace current ToggleButtons
export const ToggleButtons = ({
  id,
  toggleOptions,
  value = '',
  defaultValue,
  labelText,
  onOffToggle = true,
  name,
  height,
  isSecondary = false,
  onChange = () => {},
  update = () => {},
  activeDisabled = false,
  noPermissionDisabled = false,
  noAccessDisabled = false,
}) => {
  const [options, setOptions] = useState([])
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const disabled =
    (isSurveyActive && activeDisabled) ||
    (!hasSurveyUpdatePermission && noPermissionDisabled) ||
    noAccessDisabled
  const toolTip =
    isSurveyActive && activeDisabled
      ? TOOLTIP_MESSAGES.ACTIVE_DISABLED
      : TOOLTIP_MESSAGES.NO_PERMISSION
  useEffect(() => {
    if (toggleOptions) {
      setOptions([...toggleOptions])
    } else {
      if (onOffToggle) {
        setOptions([...twoConditions])
      } else {
        setOptions([...threeConditions])
      }
    }
  }, [onOffToggle, toggleOptions])

  const handleChange = (option) => {
    onChange(option.value)
    // if option.value is string like "yes", we have to pass same value to API
    update(option.value)
  }

  return (
    <TooltipContainer tip={toolTip} showTip={disabled}>
      <div
        className={classNames('lime-toggle-btn-group', {
          isSecondary,
        })}
      >
        {labelText && <Form.Label className="ui-label">{labelText}</Form.Label>}
        <ButtonGroup id={id}>
          {options.map((option, index) => (
            <ToggleButton
              data-testid={`toggleButton-${id}-option-${index}`}
              key={`toggleButton-${id}-option-${index}`}
              id={`toggleButton-${id}-option-${index}`}
              name={name || id || labelText}
              variant="outline-lime-toggle"
              value={option.value}
              checked={
                value === option.value ||
                (value === undefined && option.value === false) ||
                ((value === '' || value === undefined) &&
                  defaultValue === option.value)
              }
              onClick={() => handleChange(option)}
              className="toggle-button"
              disabled={disabled}
              type="radio"
            >
              <div
                style={{ height: height && height }}
                className="d-flex gap-2 justify-content-center align-items-center"
              >
                {option.icon && <option.icon size={24} />}
                {option.name}
              </div>
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
    </TooltipContainer>
  )
}
